import React from "react";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import "./App.css";

import config from "./chatbot-configs/config";
import MessageParser from "./chatbot-configs/MessageParser";
import ActionProvider from "./chatbot-configs/ActionProvider";

import ReactGA from "react-ga";

const TRACKING_ID = "6667457721"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      />
    </div>
  );
}

export default App;
