import React from "react";

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    // console.log(message);

    if (message.includes("hello")) {
      // console.log("hi");
      actions.handleHello();
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: { actions },
        });
      })}
    </div>
  );
};

export default MessageParser;
