import React from "react";
import { getAllRecords } from "../APICallsContainer/CommonAPI/GetRecordsAPI";
import {
  GET_DISTRICTS_API_CONSTANT,
  GET_DOCUMENTS_API_CONSTANT,
  GET_TALUKAS_API_CONSTANT,
  GET_VILLAGES_API_CONSTANT,
} from "../constants/APIConstants";
import { message } from "antd";

let districtOptions = [];
let talukaOptions = [];
let villageOptions = [];
let documentsLink = [];

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleHello = () => {
    const botMessage = createChatBotMessage("Hello. Nice to meet you.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const getAllDistricts = async () => {
    let response = await getAllRecords(GET_DISTRICTS_API_CONSTANT);
    console.log("districts", response);

    if (response.status) {
      districtOptions = response.payload?.data;
    } else {
      message.error(response.message);
    }
  };

  const getAllTalukas = async (id) => {
    let URL = `${GET_TALUKAS_API_CONSTANT}/${id}`;
    let response = await getAllRecords(URL);
    console.log("Talukas", response);

    if (response.status) {
      talukaOptions = response.payload?.data;
    } else {
      message.error(response.message);
    }
  };

  const getAllVillages = async (id) => {
    let URL = `${GET_VILLAGES_API_CONSTANT}/${id}`;
    let response = await getAllRecords(URL);
    console.log("Villages", response);

    if (response.status) {
      villageOptions = response.payload?.data;
    } else {
      message.error(response.message);
    }
  };

  const getDocuments = async (id) => {
    let URL = `${GET_DOCUMENTS_API_CONSTANT}/${id}`;
    let response = await getAllRecords(URL);
    console.log("documents", response);

    if (response.status) {
      let records = response.payload?.data;

      for (let index = 0; index < records.length; index++) {
        records[index].record_document.forEach((document) => {
          documentsLink.push(document.record_document_url);
        });
      }
    } else {
      message.error(response.message);
    }
  };

  const handleDistrictSelect = async (districtId) => {
    // Trigger the next widget (for taluka selection)
    const talukaMessage = createChatBotMessage("कृपया आपला तालुका निवडा", {
      widget: "talukaOptions",
    });

    // const talukaOptionsForDistrict = getOptions(district);

    await getAllTalukas(districtId);

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, talukaMessage],
      talukaOptions: talukaOptions,
    }));
  };

  const handleTalukaSelect = async (talukaId) => {
    const villageMessage = createChatBotMessage("कृपया आपले गाव निवडा", {
      widget: "villageOptions",
    });

    // const villageOptionsForTaluka = getOptions(taluka);

    await getAllVillages(talukaId);

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, villageMessage],
      villageOptions: villageOptions,
    }));
  };

  const handleVillageSelect = async (villageId) => {
    await getDocuments(villageId);

    const finalMessage = createChatBotMessage(
      <>
        आपल्या सर्व कुणबी नोंदी पाहण्यासाठी खालील लिंक वरून डॉक्युमेंट डाउनलोड
        करा:
        <ul>
          {documentsLink.map((link, index) => (
            <li key={index}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                Document {index + 1}
              </a>
            </li>
          ))}
        </ul>
      </>
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, finalMessage],
    }));
  };

  const initialOptionsSelect = async (value) => {
    if (value === "documents") {
      const message = createChatBotMessage("कृपया आपला जिल्हा निवडा", {
        widget: "districtOptions",
      });

      await getAllDistricts();

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
        districtOptions: districtOptions,
      }));
    } else if (value === "record") {
      const message = createChatBotMessage(
        <>
          {/* आपल्याकडे असलेले कुणबी नोंदीचे पुरावे आम्हाला सादर करण्यासाठी खालील
          लिंक वर क्लिक करून माहिती भरा: <br /> <br /> */}
          कुणबी प्रमाणपत्र मिळविण्यासाठी खलील लिंक वरून फॉर्म भरावा.
          <br />
          <br />
          {/* eslint-disable-next-line */}
          <a
            // href="https://app.mahakunbimaratha.org/user-page"
            // href="http://localhost:3001/user-page"
            href="https://aaplesarkar.mahaonline.gov.in/en/Login/Certificate_Documents?ServiceId=1284"
            target="_blank"
            style={{ color: "white" }}
          >
            Form Link
          </a>
        </>
      );

      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleDistrictSelect,
            handleTalukaSelect,
            initialOptionsSelect,
            handleVillageSelect,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
