import { Tag } from "antd";
import React from "react";

const DistrictOptionsComponent = ({ actions, state }) => {
  //   console.log(state);

  const districtOptions = state.districtOptions;

  const handleSelect = (value) => {
    // Use the handleDistrictSelect function from the ActionProvider
    actions.actions.handleDistrictSelect(value);
  };

  return (
    <div>
      {districtOptions.map((option) => (
        <Tag key={option.id} onClick={() => handleSelect(option.id)}>
          {option.district_name}
        </Tag>
      ))}
    </div>
  );
};

export default DistrictOptionsComponent;
