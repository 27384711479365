import { Tag } from "antd";
import React from "react";

const InitialOptionsComponent = ({ actions }) => {
  const options = [
    { label: "आपली कुणबी नोंद शोधा", value: "documents" },
    // { label: "कुणबी नोंद पुरावे सादर करा", value: "record" },
    { label: "कुणबी प्रमाणपत्र मिळवा", value: "record" },

  ];

  const handleSelect = (value) => {
    actions.actions.initialOptionsSelect(value);
  };

  return (
    <div>
      {options?.map((option) => (
        <Tag key={option.value} onClick={() => handleSelect(option.value)}>
          {option.label}
        </Tag>
      ))}
    </div>
  );
};

export default InitialOptionsComponent;
