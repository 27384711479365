import { Tag } from "antd";
import React from "react";

const TalukaOptionsComponent = ({ actions, state }) => {
  //   console.log(state.talukaOptions);

  const talukaOptions = state.talukaOptions;
  //   [
  //     { label: "Option 1", value: "option1" },
  //     { label: "Option 2", value: "option2" },
  //     // Add more options as needed
  //   ];

  const handleSelect = (value) => {
    // Use the handleDistrictSelect function from the ActionProvider
    actions.actions.handleTalukaSelect(value);
  };

  return (
    <div>
      {talukaOptions?.map((option) => (
        <Tag key={option.id} onClick={() => handleSelect(option.id)}>
          {option.taluka_name}
        </Tag>
      ))}
    </div>
  );
};

export default TalukaOptionsComponent;
