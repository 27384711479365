import { Tag } from "antd";
import React from "react";

const VillageOptionsComponent = ({ actions, state }) => {
  const villageOptions = state.villageOptions;
  //   [
  //     { label: "Option 1", value: "option1" },
  //     { label: "Option 2", value: "option2" },
  //     // Add more options as needed
  //   ];

  const handleSelect = (value) => {
    // Use the handleDistrictSelect function from the ActionProvider
    actions.actions.handleVillageSelect(value);
  };

  return (
    <div>
      {villageOptions?.map((option) => (
        <Tag key={option.id} onClick={() => handleSelect(option.id)}>
          {option.village_name}
        </Tag>
      ))}
    </div>
  );
};

export default VillageOptionsComponent;
