import { createChatBotMessage } from "react-chatbot-kit";
import DistrictOptionsComponent from "../components/DisctrictOptions/DistrictOptionsComponent";
import TalukaOptionsComponent from "../components/TalukaOptions/TalukaOptionsComponent";
import VillageOptionsComponent from "../components/VillageOptions/VillageOptionsComponent";
import InitialOptionsComponent from "../components/InitialOptions/InitialOptionsComponent";

const botName = "महा कुणबी-मराठा साथी";

// const getGreeting = () => {
//   const currentTime = new Date().getHours();

//   if (currentTime < 12) {
//     return "Good morning";
//   } else if (currentTime < 18) {
//     return "Good afternoon";
//   } else {
//     return "Good evening";
//   }
// };

const config = {
  initialMessages: [
    createChatBotMessage(`नमस्कार!
    कुणबी मराठा हेल्पलाईन मध्ये आपले स्वागत आहे!
    `),
    createChatBotMessage("कृपया खालील योग्य पर्याय निवडा", {
      widget: "initialOptions",
    }),
  ],
  widgets: [
    {
      widgetName: "initialOptions",
      widgetFunc: (props) => <InitialOptionsComponent {...props} />,
    },
    {
      widgetName: "districtOptions",
      widgetFunc: (props) => <DistrictOptionsComponent {...props} />,
    },
    {
      widgetName: "talukaOptions",
      widgetFunc: (props) => <TalukaOptionsComponent {...props} />,
    },
    {
      widgetName: "villageOptions",
      widgetFunc: (props) => <VillageOptionsComponent {...props} />,
    },
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: "#FF671F",
    },
    chatButton: {
      backgroundColor: "#5ccc9d",
    },
  },
};

export default config;
